<template>
  <div class="report-resident">
    <HeaderMenu />
    <div class="container-fluid py-4">
      <div class="d-flex justify-content-between flex-column flex-sm-row">
        <!-- 回日報表 -->
        <router-link
          v-if="$route.name === 'Notify'"
          class="
            d-inline-block
            h5
            fw-medium
            mb-3
            text-dark text-decoration-none
          "
          :to="{
            name: 'Graphical',
            query: {
              resident_id: $route.query.resident_id,
              data_type: $route.query.data_type,
              date: $route.query.date,
              notify_type: $route.query.notify_type,
              report_type: $route.query.report_type,
              from: $route.query.from
            }
          }"
        >
          <i class="bi bi-arrow-left"></i>
          {{ $t('__goBackDailyReport') }}
        </router-link>
        <!-- 回即時儀錶板 -->
        <router-link
          v-else-if="$route.query.from === 'Dashboard'"
          class="
            d-inline-block
            h5
            fw-medium
            mb-3
            text-dark text-decoration-none
            no-print
          "
          :to="{ name: 'Dashboard' }"
        >
          <i class="bi bi-arrow-left"></i>
          {{ $t('__goBackDashboard') }}
        </router-link>
        <!-- 重新搜尋 -->
        <router-link
          v-else
          class="
            d-inline-block
            h5
            fw-medium
            mb-3
            text-dark text-decoration-none
            no-print
          "
          :to="{ name: 'SearchReportResident' }"
        >
          <i class="bi bi-arrow-left"></i>
          {{ $t('__goBackSearch') }}
        </router-link>
        <div
          v-if="
            $route.name !== 'Notify' && $route.query.report_type === 'daily'
          "
          class="d-flex justify-content-between no-print"
        >
          <!-- 前一天 -->
          <router-link
            :class="{
              'd-none':
                $getTimeZoneDate(
                  new Date().addDays(-29),
                  timezone,
                  'YYYY-MM-DD'
                ) === $route.query.date
            }"
            class="btn btn-primary rounded-pill mb-3"
            tag="button"
            :to="{
              name: 'Graphical',
              query: {
                resident_id: $route.query.resident_id,
                data_type: $route.query.data_type,
                date: new Date($route.query.date)
                  .addDays(-1)
                  .format('yyyy-MM-dd'),
                notify_type: $route.query.notify_type,
                report_type: $route.query.report_type,
                from: $route.query.from
              }
            }"
          >
            {{ $t('__beforeDay') }}
          </router-link>
          <!-- 後一天 -->
          <router-link
            :class="{
              'd-none':
                $getTimeZoneDate(new Date(), timezone, 'YYYY-MM-DD') ===
                $route.query.date
            }"
            class="btn btn-primary rounded-pill ms-2 mb-3"
            tag="button"
            :to="{
              name: 'Graphical',
              query: {
                resident_id: $route.query.resident_id,
                data_type: $route.query.data_type,
                date: new Date($route.query.date)
                  .addDays(1)
                  .format('yyyy-MM-dd'),
                notify_type: $route.query.notify_type,
                report_type: $route.query.report_type,
                from: $route.query.from
              }
            }"
          >
            {{ $t('__afterDay') }}
          </router-link>
        </div>
      </div>
      <div v-if="resident" id="printBox" ref="printTest" :key="printKey">
        <div class="on-print-title">
          <!-- 列印床位編號 -->
          <span class="d-inline-block pe-2 fs-4 mb-2 fw-bold">
            {{ $t('__residentId') + '：' + $route.query.resident_id }}</span
          >
          <!-- 列印住民名稱 -->
          <span class="d-inline-block fs-4 mb-2 fw-bold">{{
            $t('__residentName') + '：' + resident.name
          }}</span>
        </div>
        <div class="card mb-3">
          <div class="card-body">
            <div
              class="
                d-flex
                flex-sm-row flex-column flex-wrap
                justify-content-between
                align-items-stretch
              "
            >
              <div class="pe-4 mb-2 no-print">
                <!-- 床位編號 -->
                <div class="bed-no fw-medium">
                  <span class="text-secondary">
                    {{ $t('__bedNo') + '：' }}
                  </span>
                  {{ (resident.device) ? resident.device.bed_number : 'N/A' }}
                </div>
                <!-- 住民編號 -->
                <div class="bed-no fw-medium">
                  <span class="text-secondary">
                    {{ $t('__residentId') + '：' }}
                  </span>
                  {{ $route.query.resident_id }}
                </div>
                <!-- 住民名稱 -->
                <h1 class="resident_name fw-medium mt-2 mb-0">
                  {{ resident.name }}
                </h1>
              </div>
              <div class="me-auto d-flex flex-column justify-content-between">
                <div class="report-date fw-medium">
                  <!-- 報表時間 -->
                  <span class="text-secondary d-none d-sm-inline-block"
                    >{{ $t('__reportTime') }} ：</span
                  >
                  <span class="d-block d-sm-inline-block mb-2">
                    <span
                      class="d-sm-none d-inline-block text-secondary fw-normal"
                      >{{ $t('__startTime') }}：</span
                    >
                    <span
                      class="d-inline-block"
                      style="white-space: nowrap !important"
                      >{{ stringDate(startAt, 'yyyy-MM-dd W hh:mm') }}</span
                    >
                  </span>
                  <span class="px-2 d-none d-sm-inline-block">~</span>
                  <span class="d-block d-sm-inline-block mb-2">
                    <span
                      class="d-sm-none d-inline-block text-secondary fw-normal"
                      >{{ $t('__entTime') }}：</span
                    >
                    <span
                      class="d-inline-block"
                      style="white-space: nowrap !important"
                      >{{ stringDate(endAt, 'yyyy-MM-dd W hh:mm') }}</span
                    >
                  </span>
                </div>
                <div
                  class="resident-data d-flex flex-column flex-sm-row flex-wrap"
                >
                  <div
                    class="
                      d-flex
                      flex-sm-column flex-md-row
                      resident-gender
                      border-end
                      pe-sm-3
                      mb-2
                    "
                  >
                    <!-- 住民性別 -->
                    <span class="pe-3 text-secondary d-inline-block">{{
                      $t('__residentGender')
                    }}</span>
                    <span class="fw-medium d-inline-block">{{
                      resident.gender === 1
                        ? $t('__male')
                        : resident.gender === 2
                        ? $t('__female')
                        : ''
                    }}</span>
                  </div>
                  <div
                    class="
                      d-flex
                      flex-sm-column flex-md-row
                      resident-age
                      border-end
                      px-sm-3
                      mb-2
                    "
                  >
                    <!-- 住民年齡 -->
                    <span class="pe-3 text-secondary d-inline-block">{{
                      $t('__age')
                    }}</span>
                    <span
                      class="fw-medium d-inline-block"
                      style="white-space: nowrap !important"
                      >{{ resident.age }}</span
                    >
                  </div>
                  <div
                    class="
                      d-flex
                      flex-sm-column flex-md-row
                      resident-identity-id
                      border-end
                      px-sm-3
                      mb-2
                    "
                  >
                    <!-- 住民身分證 -->
                    <span class="pe-3 text-secondary d-inline-block">{{
                      $t('__idNumber')
                    }}</span>
                    <span
                      class="fw-medium d-inline-block"
                      style="white-space: nowrap !important"
                      >{{ resident.id_number }}</span
                    >
                  </div>
                  <div
                    class="
                      d-flex
                      flex-sm-column flex-md-row flex-md-row
                      resident-remarks
                      px-sm-3
                    "
                  >
                    <!-- 住民註記 -->
                    <span class="pe-3 text-secondary d-inline-block">{{
                      $t('__note')
                    }}</span>
                    <span class="fw-medium d-inline-block">{{
                      resident.note
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="mt-3 no-print" v-if="$route.name !== 'Notify'">
                <!-- 通知紀錄 -->
                <router-link
                  class="btn btn-warning rounded-pill mb-3 me-3"
                  :to="{
                    name: 'Notify',
                    query: {
                      resident_id: $route.query.resident_id,
                      data_type: $route.query.data_type,
                      date: $route.query.date,
                      notify_type: $route.query.notify_type,
                      report_type: $route.query.report_type,
                      from: $route.query.from
                    }
                  }"
                >
                  {{ $t('__notifyRecord') }}
                </router-link>
                <!-- 匯出 PDF -->
                <template v-if="report">
                  <button
                    class="btn btn-primary rounded-pill mb-3"
                    @click="printPDF"
                    v-show="
                      report.bed ||
                        report.restless ||
                        report.respiration ||
                        report.status
                    "
                  >
                    {{ $t('__exportPDF') }}
                  </button>
                </template>
              </div>
            </div>
            <div
              v-if="
                $route.name === 'Graphical' &&
                  report &&
                  $route.query.report_type === 'daily'
              "
              class="border-top pt-3 mt-3"
            >
              <!-- 日報表統計資料 -->
              <div class="fw-medium mb-2">
                {{ $t('__dailyReportStatistics') }}
              </div>
              <div class="d-flex flex-column justify-content-between">
                <div
                  class="
                    daily-count
                    d-flex
                    flex-column flex-sm-row flex-wrap
                    mb-2
                  "
                >
                  <!-- 夜間離床次數 -->
                  <div
                    class="
                      d-flex
                      align-items-end align-items-sm-start align-items-lg-end
                      flex-sm-column flex-lg-row
                      border-end
                      mb-2
                      pe-sm-3
                    "
                  >
                    <span class="pe-3 text-secondary">{{
                      $t('__numberOfGettingOutOfBedAtNight')
                    }}</span>
                    <span class="fw-medium text-secondary">
                      <span class="fs-4 lh-1">{{
                        Math.floor(report.statistics.leaveBed)
                      }}</span>
                      {{ $t('__times') }}
                    </span>
                  </div>
                  <!-- 夜間休息時數 -->
                  <div
                    class="
                      d-flex
                      align-items-end align-items-sm-start align-items-lg-end
                      flex-sm-column flex-lg-row
                      border-end
                      mb-2
                      px-sm-3
                    "
                  >
                    <span class="pe-3 text-secondary">{{
                      $t('__sleepHours')
                    }}</span>
                    <span class="fw-medium text-warning">
                      <span class="fs-4 lh-1">{{
                        Math.floor(Number(report.statistics.sleepHour) * 10) /
                          10
                      }}</span>
                      {{ $t('__hour') }}
                    </span>
                  </div>
                  <!-- 通知次數 -->
                  <div
                    class="
                      d-flex
                      align-items-end align-items-sm-start align-items-lg-end
                      flex-sm-column flex-lg-row
                      border-end
                      mb-2
                      px-sm-3
                    "
                  >
                    <span class="pe-3 text-secondary">{{
                      $t('__notify')
                    }}</span>
                    <span class="fw-medium text-danger">
                      <span class="fs-4 lh-1">{{
                        Math.floor(report.statistics.notify)
                      }}</span>
                      {{ $t('__times') }}
                    </span>
                  </div>
                  <!-- 休息呼吸率 -->
                  <div
                    class="
                      d-flex
                      align-items-end align-items-sm-start align-items-lg-end
                      flex-sm-column flex-lg-row
                      border-end
                      mb-2
                      px-sm-3
                    "
                  >
                    <span class="pe-3 text-secondary">{{
                      $t('__sleepBreathingRate')
                    }}</span>
                    <span class="fw-medium text-info">
                      <span class="fs-4 lh-1">{{
                        Math.round(
                          Number(report.statistics.respiration || 0) * 10
                        ) / 10
                      }}</span>
                      {{ $t('__times_min') }}
                    </span>
                  </div>
                  <!-- 最後翻身時間 -->
                  <div
                    class="
                      d-flex
                      align-items-end align-items-sm-start align-items-lg-end
                      flex-sm-column flex-lg-row
                      mb-2
                      px-sm-3
                    "
                  >
                    <span class="pe-3 text-secondary">{{
                      $t('__latestCPTime')
                    }}</span>
                    <span class="fw-medium text-green">
                      <span
                        v-if="report.bed && report.bed.latest_cp_time"
                        class="fs-4 lh-1"
                        >{{
                          $getTimeZoneDate(
                            report.bed.latest_cp_time,
                            timezone,
                            'MM/DD HH:mm'
                          )
                        }}</span
                      >
                      <span v-else>
                        {{ $t('__noDate') }}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <router-view
          :report="report"
          :repaint="repaint"
          :key="repaint"
          :residentID="resident.id"
          :startAt="startAtUTCmilliseconds"
          :endAt="endAtUTCmilliseconds"
          :isChilineUser="isChilineUser"
          :heartRateEnabled="heartRateEnabled"
        />
      </div>
      <div v-else class="text-center">
        {{ $t('__noReportDataToBed', { resident_id: $route.query.resident_id }) }}
      </div>
    </div>
    <div
      class="modal fade cms-style"
      tabindex="-1"
      id="errorReport"
      ref="errorReportModal"
    >
      <div v-if="resident" class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="submitErrorReportForm">
            <div class="modal-header">
              <h5 class="modal-title fw-medium text-center">
                <span class="d-block">{{ resident.id_number }}</span>
                <span class="d-block">{{ $t('__errorReport') }}</span>
              </h5>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="mb-3">
                <label for="" class="form-label fw-medium">{{
                  $t('__errorType')
                }}</label>
                <select
                  v-model="errorReportModalData.type"
                  class="form-select"
                  required
                >
                  <option value="" selected>
                    {{ $t('__openThisSelectMenu') }}
                  </option>
                  <option value="1">{{ $t('__RR') }}</option>
                  <option value="2">{{ $t('__SIT') }}</option>
                  <option value="3">{{ $t('__LYB') }}</option>
                  <option value="4">{{ $t('__LVB') }}</option>
                  <option value="5">{{ $t('__restless') }}</option>
                  <option value="5">{{ $t('__heartRate') }}</option>
                  <option value="5">{{ $t('__spo2') }}</option>
                  <option value="5">{{ $t('__temp') }}</option>
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label fw-medium">{{
                  $t('__wrongTime')
                }}</label>
                <VueCtkDateTimePicker
                  v-model="errorReportModalData.trouble_time"
                  :label="$t('__wrongTime')"
                  :max-date="
                    $getTimeZoneDate(new Date(), timezone, 'YYYY-MM-DD HH:mm')
                  "
                  format="YYYY-MM-DD HH:mm"
                  formatted="YYYY-MM-DD HH:mm"
                  color="#2CBDC0"
                  button-color="#2CBDC0"
                />
              </div>
              <div class="mb-3">
                <label for="" class="form-label fw-medium">{{
                  $t('__description')
                }}</label>
                <textarea
                  v-model="errorReportModalData.description"
                  class="form-control"
                  id=""
                  rows="3"
                ></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-outline-primary rounded-pill"
                data-bs-dismiss="modal"
              >
                {{ $t('__cancel') }}
              </button>
              <button type="submit" class="btn btn-primary rounded-pill">
                {{ $t('__save') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderMenu from '@/components/layout/HeaderMenu.vue';
import { resident } from '@/http/api/resident.js';
import { report } from '@/http/api/report.js';
import { mapState, mapMutations, mapGetters } from 'vuex';
import printJS from 'print-js';
import i18n from '@/lang/lang.js';
import { Modal } from 'bootstrap';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

export default {
    name: 'ReportResident',
    components: { HeaderMenu, VueCtkDateTimePicker },
    data() {
        return {
            resident: null,
            report: null,
            start_at: null,
            end_at: null,
            repaint: false,
            printKey: true,
            bootstrap_modal: null,
            modalTitle: {
                serial_id: null,
                bed_number: null,
                resident_name: null
            },
            editDeviceId: null,
            errorReportModalData: {
                type: 1,
                trouble_time: null,
                description: null
            },
            validate: null,
            isChilineUser: false,
            heartRateEnabled: false
        };
    },
    computed: {
        ...mapState(['token', 'timezone']),
        ...mapGetters(['timeZoneOffset']),
        startAt() {
            const vm = this;
            let time;
            if (vm.$route.query.from === 'Dashboard') {
                const hours = new Date(
                    vm.$getTimeZoneDate(new Date(), vm.timezone, 'YYYY/MM/DD HH:mm')
                ).getHours();
                hours >= 12
                    ? (time = new Date(
                        new Date(vm.$route.query.date).setHours(12, 0, 0)
                    ).format('yyyy-MM-dd hh:mm'))
                    : (time = new Date(new Date(vm.$route.query.date).setHours(12, 0, 0))
                        .addDays(-1)
                        .format('yyyy-MM-dd hh:mm'));
            } else {
                time = new Date(
                    new Date(vm.$route.query.date).setHours(12, 0, 0)
                ).format('yyyy-MM-dd hh:mm');
            }
            return time;
        },
        endAt() {
            const vm = this;
            let time;
            if (vm.$route.query.from === 'Dashboard') {
                const hours = new Date(
                    vm.$getTimeZoneDate(new Date(), vm.timezone, 'YYYY/MM/DD HH:mm')
                ).getHours();
                hours >= 12
                    ? (time = new Date(new Date(vm.$route.query.date).setHours(12, 0, 0))
                        .addDays(1)
                        .format('yyyy-MM-dd hh:mm'))
                    : (time = new Date(
                        new Date(vm.$route.query.date).setHours(12, 0, 0)
                    ).format('yyyy-MM-dd hh:mm'));
            } else if (vm.$route.query.report_type === 'weekly') {
                time = new Date(new Date(vm.$route.query.date).setHours(12, 0, 0))
                    .addDays(7)
                    .format('yyyy-MM-dd hh:mm');
            } else {
                time = new Date(new Date(vm.$route.query.date).setHours(12, 0, 0))
                    .addDays(1)
                    .format('yyyy-MM-dd hh:mm');
            }
            return time;
        },
        startAtUTCseconds() {
            return this.$getUTCseconds(
                new Date(this.startAt.replace(' ', 'T') + this.timeZoneOffset)
            );
        },
        endAtUTCseconds() {
            return this.$getUTCseconds(
                new Date(this.endAt.replace(' ', 'T') + this.timeZoneOffset)
            );
        },
        startAtUTCmilliseconds() {
            return this.startAtUTCseconds * 1000;
        },
        endAtUTCmilliseconds() {
            return this.endAtUTCseconds * 1000;
        }
    },
    watch: {
        token: {
            handler: function() {
                this.getResidentDate();
            },
            deep: true
        },
        resident() {
            this.getReport();
        }
    },
    methods: {
        ...mapMutations([
            'LoadingText',
            'Loading',
            'Loaded',
            'AlertsInfo',
            'Alerted'
        ]),
        stringDate(date, format) {
            const newDate = new Date(date.replace(/-/g, '/')).format(format);
            // 為了解決 ios 不支援日期"-"格式做計算，轉成"/"後，再轉出
            return newDate;
        },
        printPDF() {
            const vm = this;
            var focuser = setInterval(
                () => window.dispatchEvent(new Event('focus')),
                500
            );
            vm.repaint = false;
            vm.LoadingText(i18n.t('__reportLoadingText'));
            vm.Loading();
            vm.printBeforeOpenCallback();
            setTimeout(() => {
                printJS({
                    printable: 'printBox',
                    type: 'html',
                    style: `
            @page { size: 297mm 210mm;  margin: 4mm; }
            .no-print{ display: none !important; }
            .svgChart{ page-break-inside: avoid; }
            .fs-4 { font-size: 20px; line-height: 1; }
          `,
                    maxWidth: '297mm',
                    showModal: true,
                    modalMessage: i18n.t('__printText'),
                    targetStyles: ['*'],
                    ignoreElements: ['Loading'],
                    onPrintDialogClose: function() {
                        clearInterval(focuser);
                        vm.repaint = false;
                        setTimeout(() => {
                            vm.onPrintDialogClose();
                            vm.Loaded();
                        }, 400);
                    }
                });
            }, 2000);
        },
        getResidentDate() {
            const vm = this;
            resident.get(vm.$route.query.resident_id, vm.token).then(res => {
                if (res.status <= 201 && res.data.status === 'success') {
                    vm.resident = res.data.data;
                    vm.heartRateEnabled = res.data.data.device?.heart_rate_enabled || false;
                } else {
                    vm.AlertsInfo({
                        state: 'error',
                        title: i18n.t('__error'),
                        info: res.data.errors.toString()
                    });
                    vm.Alerted();
                }
            });
        },
        printBeforeOpenCallback() {
            const vm = this;
            document.querySelector('body').classList.add('on-print');
            vm.repaint = true;
        },
        onPrintDialogClose() {
            const vm = this;
            document.querySelector('body').classList.remove('on-print');
            vm.repaint = true;
        },
        getReport() {
            const vm = this;
            var data = {};
            if (Array.isArray(vm.$route.query.data_type)) {
                vm.$route.query.data_type.forEach(type => {
                    switch (type) {
                    case 'restless':
                        data.report_restless = true;
                        break;
                    case 'RR':
                        data.report_respiration = true;
                        break;
                    case 'bedStatus':
                        data.report_bed = true;
                        break;
                    case 'heartRate':
                        data.report_heartRate = true;
                        break;
                    case 'spo2':
                        data.report_spo2 = true;
                        break;
                    case 'temp':
                        data.report_temp = true;
                        break;
                    case 'foraPressure':
                        data.report_fora_pressure = true;
                        break;
                    case 'foraOxygen':
                        data.report_fora_oxygen = true;
                        break;
                    case 'foraTemperature':
                        data.report_fora_temperature = true;
                        break;
                    }
                });
            } else {
                if (vm.$route.query.report_type === 'daily') {
                    switch (vm.$route.query.data_type) {
                    case 'restless':
                        data.report_restless = true;
                        break;
                    case 'RR':
                        data.report_respiration = true;
                        break;
                    case 'bedStatus':
                        data.report_bed = true;
                        break;
                    case 'heartRate':
                        data.report_heartRate = true;
                        break;
                    case 'spo2':
                        data.report_spo2 = true;
                        break;
                    case 'temp':
                        data.report_temp = true;
                        break;
                    case 'foraPressure':
                        data.report_fora_pressure = true;
                        break;
                    case 'foraOxygen':
                        data.report_fora_oxygen = true;
                        break;
                    case 'foraTemperature':
                        data.report_fora_temperature = true;
                        break;
                    }
                } else {
                    switch (vm.$route.query.data_type) {
                    case 'restless':
                        data.type = 'restless';
                        break;
                    case 'RR':
                        data.type = 'respiration';
                        break;
                    case 'bedStatus':
                        data.type = 'bed';
                        break;
                    case 'heartRate':
                        data.type = 'heartRate';
                        break;
                    case 'spo2':
                        data.type = 'spo2';
                        break;
                    case 'temp':
                        data.type = 'temp';
                        break;
                    case 'foraPressure':
                        data.type = 'foraPressure';
                        break;
                    case 'foraOxygen':
                        data.type = 'foraOxygen';
                        break;
                    case 'foraTemperature':
                        data.type = 'foraTemperature';
                        break;
                    }
                }
            }
            if (Array.isArray(vm.$route.query.notify_type)) {
                vm.$route.query.notify_type.forEach(type => {
                    switch (type) {
                    case 'restless':
                        data.notify_restless = true;
                        break;
                    case 'RR':
                        data.notify_respiration = true;
                        break;
                    case 'LYB':
                        data.notify_lying = true;
                        break;
                    case 'LVB':
                        data.notify_leave_action = true;
                        break;
                    case 'leaveTheBedTimeOut':
                        data.notify_leave_timeout = true;
                        break;
                    case 'SITTimeOut':
                        data.notify_leave_site = true;
                        break;
                    case 'heartRate':
                        data.notify_heartRate = true;
                        break;
                    case 'spo2':
                        data.notify_spo2 = true;
                        break;
                    case 'temp':
                        data.notify_temp = true;
                        break;
                    }
                });
            } else {
                switch (vm.$route.query.notify_type) {
                case 'restless':
                    data.notify_restless = true;
                    break;
                case 'RR':
                    data.notify_respiration = true;
                    break;
                case 'LYB':
                    data.notify_lying = true;
                    break;
                case 'LVB':
                    data.notify_leave_action = true;
                    break;
                case 'leaveTheBedTimeOut':
                    data.notify_leave_timeout = true;
                    break;
                case 'SITTimeOut':
                    data.notify_leave_site = true;
                    break;
                case 'heartRate':
                    data.notify_heartRate = true;
                    break;
                case 'spo2':
                    data.notify_spo2 = true;
                    break;
                case 'temp':
                    data.notify_temp = true;
                    break;
                }
            }

            var config = Object.assign({}, vm.token);
            vm.LoadingText(i18n.t('__reportLoadingText'));
            vm.Loading();
            config.params = data;
            config.params.started_at = vm.startAtUTCseconds;
            config.params.ended_at = vm.endAtUTCseconds;
            if (vm.$route.query.report_type === 'daily') {
                report.getResidentDay(vm.resident.id, config).then(res => {
                    if (res.status <= 201) {
                        vm.report = res.data.data.info;
                        if (res.data.data.device && res.data.data.device.chiline_user !== null) {
                            vm.isChilineUser = true;
                        }
                    } else {
                        vm.AlertsInfo({
                            state: 'error',
                            title: i18n.t('__error'),
                            info: res.data.errors.toString()
                        });
                        vm.Alerted();
                    }
                    vm.Loaded();
                });
            } else {
                report.getResidentWeek(vm.resident.id, config).then(res => {
                    if (res.status <= 201) {
                        vm.report = res.data.data.info;
                    } else {
                        vm.AlertsInfo({
                            state: 'error',
                            title: i18n.t('__error'),
                            info: res.data.errors.toString()
                        });
                        vm.Alerted();
                    }
                    vm.Loaded();
                });
            }
        },
        showDeviceErrorReportModal() {
            const vm = this;
            vm.errorReportModalData.type = 1;
            vm.errorReportModalData.trouble_time = null;
            vm.errorReportModalData.description = null;
            vm.$nextTick(function() {
                vm.bootstrap_modal = new Modal(vm.$refs.errorReportModal);
                vm.bootstrap_modal.show();
            });
        },
        submitErrorReportForm() {
            const vm = this;
            vm.Loading();
            resident
                .errorReport(vm.resident.id, vm.errorReportModalData, vm.token)
                .then(res => {
                    if (res.status <= 201 && res.data.status === 'success') {
                        vm.AlertsInfo({
                            state: 'success',
                            title: i18n.t('__success'),
                            info: i18n.t('__submitSuccess')
                        });
                        vm.Alerted();
                        vm.bootstrap_modal.hide();
                    } else {
                        vm.AlertsInfo({
                            state: 'error',
                            title: i18n.t('__error'),
                            info: res.data.errMsg.toString()
                        });
                        vm.Alerted();
                        vm.validate = res.data.validate;
                    }
                    vm.Loaded();
                });
        }
    },
    created() {},
    mounted() {
        const vm = this;
        if (vm.token.headers.Authorization) {
            vm.getResidentDate();
        }
    }
};
</script>

<style lang="scss">
.report-resident {
  .border-end {
    @include media-breakpoint-down(sm) {
      border: none !important;
    }
  }
  .current-data-item {
    width: 138px;
    height: 138px;
    border: 10px solid rgba(#999, 0.13);
    border-radius: 100%;
    text-align: center;
    @include media-breakpoint-down(sm) {
      width: 110px;
      height: 110px;
    }
  }
}
@page {
  size: A4 landscape;
}
</style>
